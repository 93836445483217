import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { IHTTPResponse } from 'app/core-lib/interfaces/http-response';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SmartRepliesUsage } from 'app/shared/model/answer-response.model';

@Injectable({
  providedIn: 'root',
})
export class SmartRepliesService {
  constructor(private http: HttpClient) {}

  getAIReply(reviewId) {
    const url = `${environment.onlinePath}reviews/${reviewId}/ia-reply`;
    return this.http.get<IHTTPResponse>(url);
  }

  getConfig(customerId) {
    const url = `${environment.customerPath}smart-replies/customers/${customerId}/config`;
    return this.http.get(url);
  }

  saveConfig(customerId, body) {
    const url = `${environment.customerPath}smart-replies/customers/${customerId}/config`;
    return this.http.post<IHTTPResponse>(url, body).pipe(map((response) => response.data));
  }

  getSmartReplyUsage(customerId): Observable<SmartRepliesUsage> {
    const url = `${environment.customerPath}smart-replies/customers/${customerId}/usage`;
    return this.http.get<IHTTPResponse>(url).pipe(map((response: IHTTPResponse) => response.data));
  }
}
